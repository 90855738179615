<template>
  <div>
    <el-row class="bgW">
      <el-col :span="20">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <!-- <el-form-item label="日期">
            <el-date-picker
              v-model="search.deal_month"
              type="month"
              class="w300"
              value-format="yyyy-MM"
              placeholder="选择月份"
            ></el-date-picker>
          </el-form-item> -->

		  <el-form-item label="日期">
		    <el-date-picker
		      v-model="search.start_time"
		      type="date"
		      class="w130"
		      placeholder="开始日期"
		      value-format="yyyy-MM-dd"
		    ></el-date-picker>
		    <el-date-picker
		      v-model="search.end_time"
		      type="date"
		      class="w130"
		      placeholder="结束日期"
		      value-format="yyyy-MM-dd"
		    ></el-date-picker>
		  </el-form-item>
      <el-form-item label="门店类型" prop="type">
        <el-select
          v-model="search.type"
          class="w100"
          clearable
          placeholder="门店类型"
        >
          <el-option
            v-for="item in options.type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
        </el-form>


      </el-col>
      <el-col :span="4" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button
          type="primary"
          class="mb10"
          size="mini"
          :loading="exportS"
          @click="exportExcel"
        >导出数据</el-button>
      </el-col>
    </el-row>
    <div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="tableData"
            size="small"
			max-height="750"
            border
            v-loading="loading"
            :span-method="objectSpanMethod"
            :cell-style="cellStyle"
            :header-cell-style="headerCellStyle"
          >
            <ElTableColumn label="市场名称" fixed="left" prop="name" />
            <ElTableColumn label="店面名称" fixed="left" prop="shop_name" />
            <ElTableColumn label="护理量" prop="sevice" />
			<ElTableColumn label="店长核销" prop="sevice_shop" />
			<ElTableColumn label="核销占比" prop="sevice_shop_rate" />
            <ElTableColumn label="进店数量">
              <el-table-column label="线上进店" prop="internet"></el-table-column>
              <el-table-column label="转介绍" prop="transfer_introduction"></el-table-column>
              <el-table-column label="门头外联" prop="outreach"></el-table-column>
              <el-table-column label="其他渠道" prop="qita" >
                <template slot-scope="{ row }">
                  <div
                    class="hand"
                    @click="clickQita(row,655)"
                  >{{row.qita}}</div>
			            </template>
                </el-table-column>
              <el-table-column label="合计" prop="enter_total_num"></el-table-column>
            </ElTableColumn>
            <ElTableColumn label="网络导客数" prop="internet_total" />
            <ElTableColumn label="网络进店率" prop="internet_entry_rate" :render-header="renderHeader"/>
            <ElTableColumn label="成交">
              <el-table-column label="体验个数" prop="experience_total" :render-header="renderHeader"></el-table-column>
              <el-table-column label="体验成交数" prop="experience_deal" :render-header="renderHeader"></el-table-column>
              <el-table-column label="体验成交率" prop="experience_rate" :render-header="renderHeader"></el-table-column>
              <el-table-column label="成交个数" prop="receive_deal"></el-table-column>
              <el-table-column label="新单金额" prop="new_total" :render-header="renderHeader">
				  <template slot-scope="{ row }">
				      <div
				        class="hand"
				        @click="clickBtn(row,'1')"
				      >{{row.new_total}}</div>
				  </template>
			  </el-table-column>
              <el-table-column label="均单值" prop="sheet_money"></el-table-column>
              <el-table-column label="成交率" prop="turnover_rate"></el-table-column>
            </ElTableColumn>
			<ElTableColumn label="补单金额" >
			  <el-table-column label="补单金额" :render-header="renderHeader">
			   <template slot-scope="{ row }">
			       <div
			         class="hand"
			         @click="clickBtn(row,'2')"
			       >{{row.repair_total}}</div>
			   </template>
			  </el-table-column >

			</ElTableColumn>
          <ElTableColumn label="业绩合计" prop="job_total" />
            <!-- <ElTableColumn label="进店保有率">
              <el-table-column>
                <template slot-scope="{ row }">
                  <div
                    class="hand"
                    @click="clickEnterStore(row,'enter')"
                  >{{row.enter_store_retain_rate?row.enter_store_retain_rate:'暂无数据'}}</div>
                </template>
              </el-table-column>
            </ElTableColumn>
            <ElTableColumn label="成交保有率">
              <el-table-column>
                <template slot-scope="{ row }">
                  <div
                    class="hand"
                    @click="clickEnterStore(row,'deal')"
                  >{{row.deal_retain_rate?row.deal_retain_rate:'暂无数据'}}</div>
                </template>
              </el-table-column>
            </ElTableColumn> -->
          </el-table>
          <div class="reveal">
            <span class="mr20">
              时间达成率：
              <span class="peril">{{time_achievement}}</span>
            </span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { OrderDetails } from "@/api/statistics";
import { handleExportExcel } from "@/vendor/handleExportExcel";

class Search {
  //deal_month = "";
  start_time = "";
  end_time = "";
  type = "";
}
class Options {
  type = [
      {
        id: "1",
        name: "直营"
      },
      {
        id: "2",
        name: "加盟"
      },
      {
        id: "3",
        name: "直管"
      }
    ];
}
export default {
  name: "Bargain",
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), //选项
      list: [], // 数据展示
      time_achievement: "",
      loading: false,
      exportS: false
    };
  },
  computed: {
      tableData() {
        const res = this.list.reduce((res, item) => {
          item.maxnum = 0;
          if (item.list && item.list.length > 0) {
            const goods = item.list.map(goods => Object.assign({}, item, goods));
            goods[0].maxnum = item.list.length;
            res = res.concat(goods);
          } else {
            res.push(item);
          }
          return res;
        }, []);
        return res;
      }
    },
  mounted() {
    this.getList();
  },
  methods: {
    clickQita(row,source_id){
      this.$router.push("./bargainQita?shop_id="+row.shop_id + '&source_id='+source_id  + '&start_time='+this.search.start_time + '&end_time='+this.search.end_time);
    },
    // 导出表格
    async exportExcel() {
      this.exportS = true;
      const { data } = await OrderDetails({
        ...this.search,export:1
      });
      handleExportExcel(
        [
          "市场名称",
          "店面名称",
          "护理量",
		  "店长核销量",
		  "核销占比",
          "进店数量-网络进店",
          "进店数量-转介绍",
          "进店数量-门头外联",
          "进店数量-合计",
          "线上导客数",
          "网络进店率",
          "成交-体验个数",
          "成交-体验成交数",
          "成交-体验成交率",
          "成交-成交个数",
          "成交-新单金额",
          "成交-均单值",
          "成交-成交率",
          "补单金额",
          "业绩合计"
          // "进店保有率",
          // "成交保有率"
        ],
        [
          "name",
          "shop_name",
          "sevice",
		  "sevice_shop",
		  "sevice_shop_rate",
          "internet",
          "transfer_introduction",
          "outreach",
          "enter_total_num",
          "internet_total",
          "internet_entry_rate",
          "experience_total",
          "experience_deal",
          "experience_rate",
          "receive_deal",
          "new_total",
          "sheet_money",
          "turnover_rate",
          "repair_total",
          "job_total"
          // "enter_store_retain_rate",
          // "deal_retain_rate"
        ],
        data.list,
        "进店成交明细"
      );
      this.exportS = false;
    },
    // 获取列表
    getList() {
      this.loading = true;
      OrderDetails({ ...this.search }).then(res => {
        this.list = res.data.list;
        this.time_achievement = res.data.time_achievement;
        this.loading = false;
      });
    },
	clickBtn(row,type){
	  // console.log(type);
	  const query = {

		type: type,
		//type: type == "amount"?2 : type == "developRate"?1 : 3,
	    //type: type == "popularize"?2:1,
	    shopId: row.shop_id,
	    start_time: this.search.start_time ,
	    end_time: this.search.end_time,
	  };
	  //console.log(query);
	  this.$router.push({ name: "月度新补单明细", query });
	},
    // 点击数据查看
    clickEnterStore(row, type) {
      if (type == "enter") {
        if (!row.enter_store_retain_rate) {
          this.$message({
            type: "warning",
            message: "暂无进店保有数据哦~"
          });
          return;
        }
      } else if (type == "deal") {
        if (!row.deal_retain_rate) {
          this.$message({
            type: "warning",
            message: "暂无成交保有数据哦~"
          });
          return;
        }
      }

      const query = {
        type: type,
        shop_name: row.shop_name,
        deal_uid: row.deal_uid,
        old_deal_ids: row.old_deal_ids,
        uid: row.uid,
        old_enter_store_ids: row.old_enter_store_ids
      };
      this.$router.push({ name: "进店成交详情", query });
    },

    cellStyle({row, column, rowIndex, columnIndex}){
        if(columnIndex <= 1){
            return 'background:#A8EDF1;text-align:center'
        }
        // if(columnIndex == 2){
        //     return 'background:#D8FAD1;text-align:center'
        // }
        if(columnIndex >= 2 && columnIndex <= 4){
            return 'background:#d1fbdd;text-align:center'
        }
		if(columnIndex >= 5 && columnIndex <= 8){
		    return 'background:#d4e3ea;text-align:center'
		}
        if(columnIndex == 9){
            return 'background:#eae996;text-align:center'
        }
        if(columnIndex >= 10 && columnIndex <= 11){
            return 'background:#FDE9D9;text-align:center'
        }
        if(columnIndex >= 12 && columnIndex <= 17){
            return 'background:#FFCC99;text-align:center'
        }
        if(columnIndex > 17){
            return 'background:#FFFF99;text-align:center'
        }
        return '';
      },

      headerCellStyle({row, column, rowIndex, columnIndex}){
        return 'background:#E9EDEE;text-align:center'

      },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: row.maxnum,
          colspan: 1
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    renderHeader(h, {column}) {
      switch(column.label)
      {
        case "网络进店率":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '网络进店率:线上进店的档案数据/线上导客的档案数据比率',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "体验个数":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '体验个数统计的是客户到店进行【疤痕、面部、身体、痘痘】体验服务核销记录',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "体验成交数":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '体验成交数：体验成交金额大于等于500以上的客户人数（重复客户只统计一次）',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "体验成交率":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '体验成交率：体验成交率是体验成交个数/体验个数的比例值',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "新单金额":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '新单金额:门店收款单中订单类型是新单的金额合计统计',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "补单金额":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '补单金额:门店收款单中订单类型是补单的金额合计统计',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.reveal {
  text-align: right;
  padding: 20px 0;
  .peril {
    font-weight: bold;
  }
}
.btnBox {
  text-align: right;
}
.hand {
  cursor: pointer;
}
</style>
